// Can be used e.g. on input fields (controls) in a form that change other input fields
// If the control changes the form is submitted with a "draft": true param that can be handled in the controller 
// (e.g. assign, don't save and rerender edit)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"];

  connect() {
    if(this.hasSelectTarget){
      this.element.addEventListener("change", this.processChange.bind(this));
      $(this.selectTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      });
    } else {
      this.element.addEventListener("change", this.processChange.bind(this));
    }
  }

  disconnect() {
    this.element.removeEventListener("change", this.processChange.bind(this))
  }

  findForm() {
    let parent = this.element.parentElement;
    while (parent.tagName !== "FORM") parent = parent.parentElement;
    return parent;
  }

  draftInputNode() {
    let draftInputNode = document.createElement('input')
    draftInputNode.type= "hidden"
    draftInputNode.name= "draft"
    draftInputNode.value= this.element.getAttribute("data-draft") || "true"
    return draftInputNode
  }

  processChange(event) {
    let form = this.findForm()
    form.appendChild(this.draftInputNode())
    form.noValidate = true
    if (typeof Rails !== 'undefined') {
      Rails.fire(form, 'submit') // for remote form
    } else {
      form.requestSubmit()
    }
  }
}
