//https://github.com/reenan/react-beamer/

export type BeamerConfig = {
  product_id: string
  user_id: string
  language: string
  selector: string
  filter: string
}

declare global {
  interface Window {
    beamer_config: BeamerConfig
  }
}

// TODO: type better the config object, export from here
export const loadBeamer = (config: BeamerConfig) => {
  if (!window || !config) return;

  window.beamer_config = {
    ...config,
  };

  const script = document.createElement("script");
  script.defer = true;
  script.src = "https://app.getbeamer.com/js/beamer-embed.js";
  document.head.appendChild(script);
};
