// Provides utility methods for working with forms (e.g. filling in select), ideal as an outlet for other controllers
import FroalaEditor from "froala-editor";
// Load a plugin.
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/word_paste.min.js";

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    gid: String,
  };

  connect() {
    const { positiveColor, negativeColor, I18n } = window;

    const objectId = this.gidValue;

    new FroalaEditor(`[data-froala-editor-gid-value="${CSS.escape(objectId)}"]`, {
      key: "CTD5xF3B2F2E1A2B5B5pZGCTRSAPJWTLPLZHTQQe1JGZxC4B3A3A1B5A1B1E4I1C2==",
      events: {
        initialized: function() {
          this.events.on(
            "drop",
            function(dropEvent) {
              // still able to drag and drop images this prevents it
              dropEvent.preventDefault();
              dropEvent.stopPropagation();
              return false;
            },
            true
          );
        },
        contentChanged: function() {
          const body = this.html.get();
          $(".description-status").html(I18n.t("js.saving"));
          $.ajax({
            url: "/description/save",
            method: "POST",
            data: {
              descriptable_gid: objectId,
              body: body,
            },
            success: function() {
              $(".description-status").css("color", positiveColor);
              $(".description-status").html(I18n.t("js.saved"));
            },
            error: function(e) {
              $(".description-status").css("color", negativeColor);
              $(".description-status").html(I18n.t("js.could_not_be_saved"));
              $(".description-status").append(` - ${e.responseJSON.message}`);
            },
          });
        },
      },
      tableStyles: {
        froala_full_border: "Black border",
        froala_center_table: "Center table",
      },
      tableCellStyles: {
        froala_td_no_border: "No border",
      },
      wordAllowedStyleProps: [
        "font-size",
        "background",
        "color",
        "width",
        "text-align",
        "vertical-align",
        "background-color",
        "padding",
        "margin",
        "height",
        "margin-top",
        "margin-left",
        "margin-right",
        "margin-bottom",
        "text-decoration",
        "font-weight",
        "font-style",
        "text-indent",
        "border",
        "border-.*",
      ],
      pasteAllowedStyleProps: [
        "font-size",
        "background",
        "color",
        "width",
        "text-align",
        "vertical-align",
        "background-color",
        "padding",
        "margin",
        "height",
        "margin-top",
        "margin-left",
        "margin-right",
        "margin-bottom",
        "text-decoration",
        "font-weight",
        "font-style",
        "text-indent",
        "border",
        "border-.*",
      ],
      imagePaste: false,
      toolbarSticky: false,
      attribution: false,
      quickInsertEnabled: false,
      fontSize: [
        "8",
        "9",
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "20",
        "22",
        "24",
        "26",
        "30",
        "36",
        "48",
        "60",
        "72",
        "96",
      ],
      toolbarButtons: {
        moreMisc: {
          buttons: ["undo", "redo"],
          align: "left",
          buttonsVisible: 2,
        },
        moreText: {
          buttons: [
            "bold",
            "italic",
            "underline",
            "fontSize",
            "textColor",
            "backgroundColor",
            "clearFormatting",
          ],
          align: "left",
          buttonsVisible: 4,
        },
        moreParagraph: {
          buttons: [
            "alignRight",
            "alignLeft",
            "alignCenter",
            "alignJustify",
            "formatOL",
            "formatUL",
            "outdent",
            "indent",
          ],
          align: "left",
          buttonsVisible: 0,
        },
        moreRich: {
          buttons: ["insertTable", "insertHR"],
          align: "left",
          buttonsVisible: 3,
        },
      },
    });
  }
}
