import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // in legacy there is icon transformation to svg and span is commented out
    // thus we need wrapper, library.add(fal, fas, far, fad); dom.watch();
    // once svg is gone, this code can be simplified

    const wrapper = document.createElement("span");
    const innerSpan = document.createElement("span");
    innerSpan.classList.add("fas","fa-eye","-ml-6","cursor-pointer");
    this.element.classList.add("pr-10","inline-block");
    wrapper.appendChild(innerSpan)

    wrapper.addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if(this.element.getAttribute('type') === 'password'){
        this.element.setAttribute('type', 'text');        
        wrapper.firstChild.classList.remove('fa-eye');
        wrapper.firstChild.classList.add('fa-eye-slash');


      }else{
        this.element.setAttribute('type', 'password');
        wrapper.firstChild.classList.remove('fa-eye-slash');
        wrapper.firstChild.classList.add('fa-eye');
      }
    });

    this.element.insertAdjacentElement('afterend', wrapper);
  }
}
