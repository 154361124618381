import * as Charts from './index';

export default class ChartFactory {
  static factories = {
    asset_ownership_pie: Charts.AssetOwnershipPie,
    basic_chart: Charts.BasicChart,
    insurance_value: Charts.InsuranceValue,
    past_value_chart: Charts.PastValueChart,
    past_performance_chart: Charts.PastPerformanceChart,
    aggregate_loan_balances: Charts.AggregateLoanBalances
  }

  static async createChart(chartName, chartOptions) {
    const ChartClass = this.factories[chartName];

    if (!ChartClass) {
      throw new Error(`Unknown chart type: ${chartName}`);
    }

    return new ChartClass(chartOptions);
  }
}
