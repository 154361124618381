import { Controller } from "@hotwired/stimulus";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";

export default class extends Controller {
  static targets = ["list", "input", "nameInput", "container", "searchInput", "tag", "placeholder"];

  initialize() {
    this.displayTag();
  }

  toggle(e) {
    e.preventDefault();
    if (this.listTarget.classList.contains("hidden")) {
      this.open();
    } else {
      this.close();
    }
  }

  select(e) {
    const objectId = e.currentTarget.dataset.value;
    const objectName = e.currentTarget.dataset.name;
    this.inputTarget.value = objectId;
    if (this.hasNameInputTarget) this.nameInputTarget.value = objectName;
    const nameToDisplay = isUndefined(objectName) ? objectId : objectName;

    this.tagTarget.querySelector("[data-js='label']").textContent = nameToDisplay;
    this.displayTag();
    this.close();
  }
  removeTagAndValue(e) {
    e.stopPropagation();
    this.inputTarget.value = null;
    if (this.hasNameInputTarget) this.nameInputTarget.value = null;
    this.tagTarget.classList.add("hidden");
    this.placeholderTarget.classList.remove("hidden");
    this.searchInputTarget.value = "";
  }
  
  displayTag() {
    if (isEmpty(this.inputTarget.value)) {
      this.tagTarget.classList.add("hidden");
    } else {
      this.placeholderTarget.classList.add("hidden");
      this.tagTarget.classList.remove("hidden");
    }
  }
  
  open() {
    this.listTarget.classList.remove("hidden");
    this.containerTarget.classList.add("outline");
    this.placeholderTarget.classList.add("hidden");
    this.tagTarget.classList.add("hidden");
    this.searchInputTarget.focus();
  }

  close() {
    this.listTarget.classList.add("hidden");
    this.containerTarget.classList.remove("outline");
    if (isEmpty(this.inputTarget.value)) {
      this.placeholderTarget.classList.remove("hidden");
    } else {
      this.tagTarget.classList.remove("hidden")
    }
    this.searchInputTarget.value = "";
  }
  
  closeOnOutsideClick(event) {
    if(this.listTarget.classList.contains("hidden")) return;
    if(this.element === event.target || this.element.contains(event.target)) return;
    this.close();
  }
}
