import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["opened", "closed"]
  static values = {
    opened: Boolean,
    storageKey: String
  }
  connect() {
    this.load()
    this.openedValue ? this.open() : this.close()
  }

  toggle(event) {
    event.preventDefault()
    this.openedValue ? this.close() : this.open()
  }

  open(event) {
    if (event) {
      event.preventDefault()
    }
    this.element.classList.add(this.openedClass)
    this.element.classList.remove(this.closedClass)
    this.openedValue = true
    this.store()
  }

  close(event) {
    if (event) {
      event.preventDefault()
    }
    this.element.classList.add(this.closedClass)
    this.element.classList.remove(this.openedClass)
    this.openedValue = false
    this.store()
  }

  load() {
    if (this.storageKeyValue) {
      if (window.localStorage.getItem(this.storageKeyValue)) {
        let storedValue = (window.localStorage.getItem(this.storageKeyValue) == "true")
        this.openedValue = storedValue
      }
    }
  }

  store() {
    if (this.storageKeyValue) {
      window.localStorage.setItem(this.storageKeyValue, this.openedValue ? "true" : "false")
    }
  }
}
