// app/javascript/controllers/tooltip_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.createTooltip();
  }

  disconnect() {
    this.destroyTooltip();
  }

  createTooltip() {
    this.element.addEventListener('mouseenter', this.showTooltip.bind(this));
    this.element.addEventListener('mouseleave', this.hideTooltip.bind(this));
  }

  showTooltip() {
    const tooltipContent = this.element.dataset.tooltipInnerHtml || this.element.dataset.title;
    const placement = this.element.dataset.placement || 'top';

    if (!tooltipContent ) return;

    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'z-300 transition-opacity ease-in-out absolute bg-black text-white text-sm p-1 rounded bs-override overflow-auto';

    if (this.element.dataset.tooltipInnerHtml != null) tooltipElement.innerHTML = this.element.dataset.tooltipInnerHtml;
    if (this.element.dataset.title != null) tooltipElement.textContent = this.element.dataset.title;

    document.body.appendChild(tooltipElement);

    const arrowElement = document.createElement('div');
    arrowElement.className = 'z-300 transition-opacity ease-in-out absolute bg-black w-2_5 h-2_5 rotate-45 bs-override';
    document.body.appendChild(arrowElement);

    const { top, left, width, height } = this.element.getBoundingClientRect();
    const tooltipHeight = tooltipElement.offsetHeight;
    const tooltipWidth = tooltipElement.offsetWidth;

    if (placement === 'left') {
      const elementCenter = top + height / 2;
      const tooltipCenter = tooltipHeight / 2;

      tooltipElement.style.top = `${elementCenter - tooltipCenter}px`;
      tooltipElement.style.left = `${left - tooltipWidth}px`;

      arrowElement.style.top = `${elementCenter - 6}px`;
      arrowElement.style.left = `${left - 6}px`;
    } else {
      tooltipElement.style.position = 'absolute';
      tooltipElement.style.top = `${top - tooltipHeight - 12}px`;
      tooltipElement.style.left = `${left + width / 2 - tooltipWidth  / 2}px`;

      arrowElement.style.top = `${top - 18}px`;
      arrowElement.style.left = `${left + width / 2 - 6}px`;
    }

    this.tooltipElement = tooltipElement;
    this.arrowElement = arrowElement;
  }

  hideTooltip() {
    if (this.tooltipElement) {
      this.tooltipElement.remove();
      this.arrowElement.remove();
      this.tooltipElement = null;
      this.arrowElement = null;
    }
  }

  destroyTooltip() {
    this.hideTooltip();
    this.element.removeEventListener('mouseenter', this.showTooltip);
    this.element.removeEventListener('mouseleave', this.hideTooltip);
  }
}
