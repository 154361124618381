import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["percentage"]

  connect() {
    this.split();
  }

  percentageTargetConnected(element) {
    this.split();
  }

  percentageTargetDisconnected(element) {
    this.split();
  }

  split() {
    const total = this.percentageTargets.length;

    this.percentageTargets.forEach((element, index) => {
      element.value = (100.0 / total).toFixed(2);
    })
  }
}
