import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["more", "less"]
  static targets = ["switchable"]

  switch() {
    this.switchableTargets.forEach(target => {
      if (target.classList.contains(this.moreClass)) {
        target.classList.remove(this.moreClass);
        target.classList.add(this.lessClass);
      } else if (target.classList.contains(this.lessClass)) {
        target.classList.remove(this.lessClass);
        target.classList.add(this.moreClass);
      }
    });

    if (this.switchableTargets.length > 0) {
      const firstTarget = this.switchableTargets[0];
      this.openedValue = firstTarget.classList.contains(this.moreClass);
    }
  }
}
