import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    opened: Boolean,
  }
  static targets = ["slideIn"]

  connect() {
    this.openedValue ? this.open() : this.close();
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openedValue ? this.close() : this.open();
  }

  openedValueChanged(current, previous) {
    this.slideInTarget.setAttribute("data-opened", current);
  }

  open() {
    this.element.classList.add("chart--opened");
    this.openedValue = true
    //hackety hack
    const navbar = document.querySelector('.navigation-container')
    navbar.style.zIndex = 0;
  }

  close() {
    this.element.classList.remove("chart--opened");
    this.openedValue = false;
    //hackety hack
    const navbar = document.querySelector('.navigation-container')
    navbar.style.zIndex = 1;
  }
}
