// app/javascript/controllers/button_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    event: { type: String, default: "change" }
  }

  click() {
    const eventName = this.eventValue;
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: this.element.dataset.detail
    });
    this.element.dispatchEvent(event);
  }
}

