import * as Tables from './index';

export default class TableFactory {
  static factories = {
    pe_portfolio_positions: Tables.PePortfolioPositionTable,
    regular_portfolio_table: Tables.RegularPortfolioTable,
    yearly_situation_table: Tables.YearlySituationTable,
    amortization_table: Tables.AmortizationTable,
  }

  static createTable(tableName, tableOptions) {
    const TableClass = this.factories[tableName];

    if (!TableClass) {
      throw new Error(`Unknown table type: ${tableName}`);
    }
    return new TableClass(tableOptions);
  }
}
