// Can forward data to the form select controller (outlet)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["form-select"]

  fillSelect() {
    this.formSelectOutlet.fillSelect({text: this.element.innerText, value: this.element.dataset.optionvalue})
  }
}
