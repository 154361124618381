import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeout: Number,
    animationDelay: Number,
    animationClass: String
  }

  connect() {
    if (this.timeoutValue) {
      setTimeout(() => {
        this.dismiss()
      }, this.timeoutValue)
    }
  }

  dismiss() {
    if (this.animationDelayValue && this.animationClassValue) {
      this.element.classList.add(this.animationClassValue)
      setTimeout( () => {
        this.element.remove()
      }, this.animationDelayValue)
    } else {
      this.element.remove();
    }
  }
}
