import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    fallback: { type: String, default: "/" },
    closeUrl: { type: String, default: "/" },
  }

  back() {
    if (history && history.length) {
      history.back();
      return false;
    }
  }

  closeWithUrl() {
    Turbo.visit(this.closeUrlValue);
  }
}
