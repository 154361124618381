import { Controller } from "@hotwired/stimulus";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";

// Connects to data-controller="insurance-company-name-selector"
export default class extends Controller {
  static targets = ["list", "input", "container", "icon", "searchInput", "tag"];

  initialize() {
    this.displayTag();
  }

  toggle(e) {
    e.preventDefault();
    if (this.listTarget.classList.contains("hidden")) {
      this.open();
    } else {
      this.close();
    }
  }
  select(e) {
    const companyId = e.currentTarget.dataset.value;
    const companyName = e.currentTarget.dataset.name;
    this.inputTarget.value = companyId;
    const nameToDisplay = isUndefined(companyName) ? companyId : companyName;

    this.tagTarget.querySelector("[data-js='label']").textContent = nameToDisplay;
    this.displayTag();
    this.close();
  }
  removeTagAndValue() {
    this.inputTarget.value = null;
    this.tagTarget.classList.add("hidden");
  }
  displayTag() {
    if (isEmpty(this.inputTarget.value)) {
      this.tagTarget.classList.add("hidden");
    } else {
      this.tagTarget.classList.remove("hidden");
    }
  }
  open() {
    this.listTarget.classList.remove("hidden");
    this.containerTarget.classList.add("outline");
    this.iconTarget.classList.remove("fa-caret-down");
    this.iconTarget.classList.add("fa-caret-up");
    this.searchInputTarget.focus();
  }
  close() {
    this.iconTarget.classList.remove("fa-caret-up");
    this.iconTarget.classList.add("fa-caret-down");
    this.listTarget.classList.add("hidden");
    this.containerTarget.classList.remove("outline");
    this.searchInputTarget.value = "";
  }
  closeOnOutsideClick(event) {
    if(this.listTarget.classList.contains("hidden")) return;
    if(this.element === event.target || this.element.contains(event.target)) return;
    this.close();
  }
}
