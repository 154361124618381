// Fonts, will be whitelisted #9821, don't add new packs
import setupFontAwesome from "@src/initializers/font-awesome";
import WebFont from "webfontloader";

setupFontAwesome();

WebFont.load({
  google: {
    families: ["Lato:300,400,700", "Roboto:300,400,700", "Open+Sans:300,400,700"],
  },
  timeout: 2000,
});

// Turbo + Stimulus (disabled by default if data-turbo false on body)
import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import "@hotwired/turbo-rails";

const application = Application.start();
const controllers = import.meta.glob("/src/**/*_controller.js", { eager: true });
registerControllers(application, controllers);

// easy way to share small function vue/legacy
window.sanitizeAll = function(input) {
  return input.replace(/(<([^>]+)>)/gi, "");
};
