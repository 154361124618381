// app/javascript/controllers/file_upload_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { allowedExtensions: Array }
  static targets = ["fileInput", "dropZone", "loadArea", "fileInfo", "fileError", "fileName", "progressBarContainer", "submitButton"]

  connect() {
    this.authorizedExtensions = ['pdf'];
    this.allowedExtensions = this.allowedExtensionsValue.filter(ext => this.authorizedExtensions.includes(ext));
    this.dropZoneTarget.addEventListener('dragenter', this.handleDragEnter.bind(this));
    this.dropZoneTarget.addEventListener('dragleave', this.handleDragLeave.bind(this));
    this.dropZoneTarget.addEventListener('dragover', this.handleDragOver.bind(this));
    this.dropZoneTarget.addEventListener('drop', this.handleDrop.bind(this));
    this.uploadInterval = null;
  }

  handleDragEnter(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.add('drag-over');
  }

  handleDragLeave(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove('drag-over');
  }

  handleDragOver(event) {
    event.preventDefault();
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove('drag-over');

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileInputTarget.files = files;
      this.simulateUpload();
    }
  }

  validateFileExtension(file) {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return this.allowedExtensions.includes(fileExtension);
  }

  startLoad() {
    this.loadAreaTarget.style.display = "none";
    this.progressBarContainerTarget.style.display = "flex";
  }

  showError() {
    this.loadAreaTarget.style.display = "none";
    this.fileErrorTarget.style.display = "flex";
  }

  selectFile() {
    this.fileInputTarget.click();
  }

  simulateUpload() {
    const file = this.fileInputTarget.files[0];
    if (!file) return;

    if (this.validateFileExtension(file)) {
      this.startLoad();
      let loadCount = 0;

      this.uploadInterval = setInterval(() => {
        if (loadCount >= 5) {
          clearInterval( this.uploadInterval);
          this.stopLoad();
          this.toggleSubmitButton(true);
        } else {
          loadCount += 1;
        }
      }, 200);
    } else {
      this.showError();
    }
  }

  stopLoad() {
    this.progressBarContainerTarget.style.display = 'none';
    this.fileInfoTarget.style.display = 'flex';
    this.fileNameTarget.textContent = this.fileInputTarget.files[0].name
  }

  removeFile() {
    clearInterval(this.uploadInterval);
    this.uploadInterval = null;
    this.fileInputTarget.value = '';
    this.fileInfoTarget.style.display = 'none';
    this.progressBarContainerTarget.style.display = 'none';
    this.fileErrorTarget.style.display = 'none';
    this.loadAreaTarget.style.display = 'flex';
    this.toggleSubmitButton(false);
  }

  toggleSubmitButton(isEnabled) {
    if (isEnabled) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', true);
    }
  }
}
