export default class TableAbstract {
  constructor(tableOptions) {
    this.tableOptions = tableOptions;
  }

  // Abstract method to be implemented by subclasses
  dataTableOptions() {
    throw new Error('tableOptions() must be implemented in subclasses');
  }
}
