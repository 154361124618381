<template>
  <UserDropdown :userName="props.name">
    <DropdownItem @click.stop="goToProfile">
      <i class="fa-regular fa-user-cog w-6" />
      <span class="ml-1">{{ $t("layout.nav.company.profile") }}</span>
    </DropdownItem>
  </UserDropdown>
</template>

<script setup lang="ts">
import UserDropdown from "@laila/components/dropdown/UserDropdown.vue";
import DropdownItem from "@laila/components/dropdown/DropdownItem.vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  profilePath: {
    type: String,
    required: true,
  },
});

const goToProfile = () => {
  window.location.href = props.profilePath;
};
</script>
