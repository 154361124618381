import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "tab"]
  static values = {
    currentTab: String
  }

  select({ detail: value }) {
    this.currentTabValue = value;
  }

  currentTabValueChanged() {
    this.tabTargets.forEach((tab) => {
      tab.classList.add("d-none");
      if( tab.dataset.tab == this.currentTabValue) {
        tab.classList.remove("d-none");
      }
    })
  }
}
