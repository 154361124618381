import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["childTemplate", "childList", "child"]

  addChild(e) {
    e.preventDefault();
    e.stopPropagation();
    const clone = this.childTemplateTarget.content.cloneNode(true);
    this.childListTarget.appendChild(clone);
  }

  childTargetConnected(element) {
    const uniqueId = new Date().getTime().toString();
    element.outerHTML = element.outerHTML.replace(/JS_REPLACE_INDEX/g, uniqueId); 
  }

  removeChild(event) {
    event.preventDefault();
    event.stopPropagation();
    const { params } = event;
    this.childTargets.find(e => e.dataset.childid == params.childid).remove()
  }
}
