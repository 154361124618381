<template>
  <Dropdown :customStyle="customStyle">
    <template #activator>
      <span class="activator-initials">{{ userName?.substring(0, 1) }}</span>
      <i class="activator-chevron fa-solid fa-chevron-down" />
    </template>

    <DropdownItem class="user-dropdown__user-item">
      <div class="user-dropdown__user-initials">
        <span> {{ userName.substring(0, 1) }} </span>
      </div>
      <strong style="white-space: pre-wrap"> {{ userName }} </strong>
    </DropdownItem>

    <slot />
    <DropdownItem @click.stop="onSignOut" class="user-dropdown__sign-out">
      <i class="fa-regular fa-sign-out w-6" />
      <span class="ml-1">{{ $t("devise.sign_out") }}</span>
    </DropdownItem>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from "@laila/components/dropdown/Dropdown.vue";
import DropdownItem from "@laila/components/dropdown/DropdownItem.vue";

export type Props = {
  userName?: string | null;
};

const onSignOut = () => {
  window.location.href = "/users/sign_out";
};

const props = withDefaults(defineProps<Props>(), {
  userName: null,
});

const customStyle = {
  activator: {
    display: "flex",
    alignItems: "center",
  },
  dropdownList: {
    maxHeight: "unset",
  },
};

const emit = defineEmits<{
  (e: "sign-out"): void;
}>();
</script>

<style scoped lang="scss">
.activator-initials {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: space-around;
  background-color: var(--light);
  border-radius: 50%;
  margin-right: 8px;
}
.user-dropdown__user-initials span {
  margin: 0px;
}
.user-dropdown__user-initials {
  display: flex;
  min-width: 32px;
  min-height: 32px;
  align-items: center;
  justify-content: space-around;
  background-color: var(--light);
  border-radius: 50%;
  margin-right: 8px;
}
:deep(.dropdown-list) {
  width: 248px;
  ul li {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
  }
}
:deep(#activator:hover) {
  .activator-chevron {
    color: var(--primary);
  }
  .activator-initials {
    background-color: var(--primary);
    color: white;
  }
}
ul li.user-dropdown {
  &__sign-out {
    color: red;
    border-top: 1px solid #e0e5ea;
  }
  &__user-item {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    pointer-events: none;
    border-bottom: 1px solid #e0e5ea;
    padding-left: 20px;
  }
}
</style>
