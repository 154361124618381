import { Controller } from "@hotwired/stimulus"

// For the moment we are relying on the legacy jquery implementation here.

export default class extends Controller {
  static targets = ["input", "info"]
  static values = { "userId": String }

  check() {
    const postData = {
      password: this.inputTarget.value,
      user_id: this.userIdValue
    }

    const csrfToken = document.querySelector("[name='csrf-token']").content

    /* Not sure if this is secure, but taken from previous impl ??? Post needed to not expose password over the wire */
    fetch( "/password_check/", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      dataType: "json",
      body: JSON.stringify(postData)
    }).then((response) => response.json()).then((body) => {
      if (body.strong) {
        this.element.classList.add("has-success")
        this.element.classList.remove("has-error")
        this.infoTarget.classList.add("has-success")
        this.infoTarget.classList.remove("has-error")
      } else {
        this.element.classList.remove("has-success")
        this.element.classList.add("has-error")
        this.infoTarget.classList.remove("has-success")
        this.infoTarget.classList.add("has-error")
      }

      this.infoTarget.innerText = body.message
    })
  }
}
