import { Controller } from "stimulus";
import Highcharts from "highcharts";

export default class extends Controller {
  static values = {
    chartOptions: Object,
  }

  initialize() {
    this.chart = null;
  }

  connect() {
    if (!this.element.hasChildNodes()) {
      this.chart = Highcharts.chart(this.element, this.highchartsOptions);
    }
  }

  disconnect() {
    if(this.chart){
      this.chart.destroy();
    }
  }

  get highchartsOptions() {
    const {
      plotOptions,
      series,
      title,
      axesStyle,
      maxValue,
      xAxis,
    } = this.chartOptionsValue;

    return {
      chart: {
        zoomType: "x",
        height: 230,
        parallelAxes: {
          endOnTick: false,
          minPadding: 0,
          maxPadding: 0,
        },
      },
      title: title,
      plotOptions: plotOptions,
      series: series,
      tooltip: this.tooltipOptions,
      xAxis: {
        type: "datetime",
        title: {
          text: undefined,
        },
        labels: axesStyle,
        ...xAxis,
      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: axesStyle,
        max: maxValue,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    };
  }

  get tooltipOptions() {
    const { tooltip } = this.chartOptionsValue;

    return {
      backgroundColor: tooltip?.backgroundColor,
      style: {
        color: "white",
      },
      borderRadius: 8,
      shadow: false,
      borderWidth: 0,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 5,
      paddingBottom: 5,
      positioner: function(labelWidth, labelHeight, point) {
        var tooltipX, tooltipY;
        if (point.plotX > this.chart.plotWidth * 2 / 3) {
          tooltipX = point.plotX + this.chart.plotLeft - labelWidth - 12;
        } else {
          tooltipX = point.plotX + this.chart.plotLeft + 12;
        }
        tooltipY = point.plotY + this.chart.plotTop - labelHeight / 2;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      formatter: !tooltip?.shared
        ? function() {
            var date = Highcharts.dateFormat("%d/%m/%Y", this.x);
            var value = this.point.format;
            return date + ": " + `${value}`;
          }
        : null,
      pointFormat: '{series.name}: <b>{point.format}</b><br/>',
      shared: tooltip?.shared,
      dateTimeLabelFormats: {
        day: '%d %B %Y',
        month: '%B %Y',
        year: '%Y'
      },
    };
  }
}
