function highchartLang() {
  const translations = {
    "nl": dutchHighchartTranslations,
    "fr": frenchHighchartTranslations,
    "de": germanHighchartTranslations,
  };

  return translations[I18n.locale] ? translations[I18n.locale]() : dutchHighchartTranslations(); // Default to Dutch if no match
}

export function messageTranslations(emptyTable) {
  const translations = {
    "nl": dutchDatatableTranslations,
    "fr": frenchDatatableTranslations,
    "de": germanDatatableTranslations,
  };

  return translations[I18n.locale] ? translations[I18n.locale](emptyTable) : dutchDatatableTranslations(emptyTable); // Default to Dutch if no match
}

function editorTranslations() {
  const translations = {
    "nl": dutchEditorTranslations,
    "fr": frenchEditorTranslations,
    "de": germanEditorTranslations,
  };

  return translations[I18n.locale] ? translations[I18n.locale]() : dutchEditorTranslations(); // Default to Dutch if no match
}

// Define the translation functions
function frenchEditorTranslations() {
  return {
    create: {
      button: "Ajouter",
      title: "Ajouter une nouvelle ligne",
      submit: "Créer"
    },
    edit: {
      button: "Modifier",
      title: "Modifier l'information",
      submit: "Actualiser"
    },
    remove: {
      button: "Supprimer",
      title: "Supprimer",
      submit: "Supprimer",
      confirm: {
        _: "Êtes-vous sûr de vouloir supprimer %d lignes?",
        1: "Êtes-vous sûr de vouloir supprimer 1 ligne?"
      }
    },
    error: {
      system: "Une erreur s’est produite"
    },
    datetime: {
      previous: 'Précédent',
      next: 'Suivant',
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      weekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
    }
  };
}

function dutchEditorTranslations() {
  return {
    create: {
      button: "Toevoegen",
      title: "Voeg een rij toe",
      submit: "Toevoegen"
    },
    edit: {
      button: "Wijzig",
      title: "Wijzig de informatie",
      submit: "Update"
    },
    remove: {
      button: "Verwijderen",
      title: "Verwijderen",
      submit: "Verwijderen",
      confirm: {
        _: "Weet u zeker dat u %d regels wilt verwijderen?",
        1: "Weet u zeker dat u 1 regel wilt verwijderen?"
      }
    },
    error: {
      system: "Sorry, er ging iets mis"
    },
    datetime: {
      previous: "Vorige",
      next: "Volgende",
      months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
      weekdays: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']
    }
  };
}

function germanEditorTranslations() {
  return {
    create: {
      button: "Hinzufügen",
      title: "Zeile hinzufügen",
      submit: "Hinzufügen"
    },
    edit: {
      button: "Bearbeiten",
      title: "Ändern Sie die Informationen",
      submit: "Aktualisieren"
    },
    remove: {
      button: "Löschen",
      title: "Löschen",
      submit: "Löschen",
      confirm: {
        _: "Sind Sie sicher, dass Sie %d Zeilen löschen möchten?",
        1: "Sind Sie sicher, dass Sie 1 Zeile löschen möchten?"
      }
    },
    error: {
      system: "Leider ist etwas schiefgelaufen"
    },
    datetime: {
      previous: "Zurück",
      next: "Nächste",
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    }
  };
}

function frenchDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || 'Aucune donnée disponible dans le tableau';
  return {
    processing: 'Traitement en cours...',
    search: 'Rechercher&nbsp;:',
    lengthMenu: 'Afficher _MENU_ éléments',
    info: 'Affichage de l\'élément _START_ à _END_ sur _TOTAL_ éléments',
    infoEmpty: 'Affichage de l\'élément 0 à 0 sur 0 éléments',
    infoFiltered: '(filtré de _MAX_ éléments au total)',
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: 'Chargement en cours...',
    zeroRecords: 'Aucun élément à afficher',
    emptyTable: emptyTable,
    buttons: {
      colvis: 'Changer les colonnes'
    },
    paginate: {
      first: 'Premier',
      previous: 'Précédent',
      next: 'Suivant',
      last: 'Dernier'
    },
    aria: {
      sortAscending: ': activer pour trier la colonne par ordre croissant',
      sortDescending: ': activer pour trier la colonne par ordre décroissant'
    },
    death_beneficiaries: 'Bénéficiaires décès'
  };
}

function dutchDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || "Geen resultaten aanwezig in de tabel";
  return {
    processing: "Bezig...",
    search: "Zoeken:",
    lengthMenu: "_MENU_ resultaten weergeven",
    info: "_START_ tot _END_ van _TOTAL_ resultaten",
    infoEmpty: "Geen resultaten om weer te geven",
    infoFiltered: " (gefilterd uit _MAX_ resultaten)",
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: "Een moment geduld aub - bezig met laden...",
    zeroRecords: "Geen resultaten gevonden",
    emptyTable: emptyTable,
    paginate: {
      first: "Eerste",
      previous: "Vorige",
      next: "Volgende",
      last: "Laatste"
    },
    aria: {
      sortAscending: ": activeer om kolom oplopend te sorteren",
      sortDescending: ": activeer om kolom aflopend te sorteren"
    }
  };
}

function germanDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || "Keine Daten in der Tabelle vorhanden";
  return {
    processing: "Bitte warten...",
    search: "Suchen:",
    lengthMenu: "_MENU_ Einträge anzeigen",
    info: "_START_ bis _END_ von _TOTAL_ Einträgen",
    infoEmpty: "0 bis 0 von 0 Einträgen",
    infoFiltered: " (gefiltert von _MAX_ Einträgen)",
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: "Wird geladen...",
    zeroRecords: "Keine Einträge vorhanden.",
    emptyTable: emptyTable,
    paginate: {
      first: "Erste",
      previous: "Zurück",
      next: "Nächste",
      last: "Letzte"
    },
    aria: {
      sortAscending: ": aktivieren, um Spalte aufsteigend zu sortieren",
      sortDescending: ": aktivieren, um Spalte absteigend zu sortieren"
    }
  };
}

function dutchHighchartTranslations() {
  return {
    loading: 'Wordt geladen...',
    months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
    weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    shortMonths: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    exportButtonTitle: "Exporteren",
    printButtonTitle: "Printen",
    rangeSelectorFrom: "Vanaf",
    rangeSelectorTo: "Tot",
    rangeSelectorZoom: "Periode",
    downloadPNG: 'Download als PNG',
    downloadJPEG: 'Download als JPEG',
    downloadPDF: 'Download als PDF',
    downloadSVG: 'Download als SVG',
    resetZoom: 'Reset',
    resetZoomTitle: 'Reset',
    thousandsSep: '.',
    decimalPoint: ',',
    noData: 'Geen resultaten'
  };
}

function frenchHighchartTranslations() {
  return {
    loading: 'Chargement...',
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    shortMonths: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'juil', 'aoû', 'sep', 'oct', 'nov', 'déc'],
    exportButtonTitle: "Exporter",
    printButtonTitle: "Imprimer",
    rangeSelectorFrom: "Du",
    rangeSelectorTo: "au",
    rangeSelectorZoom: "Période",
    downloadPNG: 'Télécharger en PNG',
    downloadJPEG: 'Télécharger en JPEG',
    downloadPDF: 'Télécharger en PDF',
    downloadSVG: 'Télécharger en SVG',
    resetZoom: "Réinitialiser le zoom",
    resetZoomTitle: "Réinitialiser le zoom",
    thousandsSep: " ",
    decimalPoint: ',',
    noData: 'Pas de données'
  };
}

function germanHighchartTranslations() {
  return {
    loading: 'Daten werden geladen...',
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    exportButtonTitle: "Exportieren",
    printButtonTitle: "Drucken",
    rangeSelectorFrom: "Von",
    rangeSelectorTo: "Bis",
    rangeSelectorZoom: "Zeitraum",
    downloadPNG: 'Download als PNG-Bild',
    downloadJPEG: 'Download als JPEG-Bild',
    downloadPDF: 'Download als PDF-Dokument',
    downloadSVG: 'Download als SVG-Bild',
    resetZoom: 'Zoom zurücksetzen',
    resetZoomTitle: 'Zoom zurücksetzen',
    thousandsSep: '.',
    decimalPoint: ',',
    noData: 'Keine Daten'
  };
}
