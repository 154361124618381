<template>
  <Popper arrow hover placement="bottom">
    <div id="help-button" role="button" @click="onClickWidget">
      <i class="fa fa-regular fa-question-circle help-button__icon" />
    </div>
    <template #content>
      <span v-html="$t('layout.navbar.help_tooltip')" />
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "vue3-popper";
import { loadFreshdesk, FreshdeskConfig } from "@utilities/freshdesk";
import { ref } from "vue";
import { onMounted } from "vue";

type Props = {
  config: FreshdeskConfig;
};

declare global {
  interface Window {
    FreshworksWidget: Function;
  }
}

const props = defineProps<Props>();

onMounted(() => {
  props.config && loadFreshdesk({ ...props.config });
});

const onClickWidget = () => {
  if (!window.FreshworksWidget) return;
  window.FreshworksWidget("open");
};
</script>

<style scoped lang="scss">
#help-button {
  cursor: pointer;
  align-items: center;
  width: 32px;
  height: 32px;
  justify-content: space-around;
  display: flex;
  margin-right: 1rem;
  &:hover {
    .help-button__icon {
      color: var(--primary);
    }
  }
}
.help-button__icon {
  color: var(--main-font-color);
  font-size: 16px;
}
</style>
