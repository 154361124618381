import { Controller } from "@hotwired/stimulus"
import IMask from "imask";

const maskOptions = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2,
  radix: ','
};

export default class extends Controller {
  connect() {
    this.mask = IMask(this.element, maskOptions);
  }

  disconnect() {
    this.mask?.destroy();
  }

}

