// inpired from https://github.com/WizardComputer/stimulus-multiselect#good-to-know
const activeSelector = "[aria-selected='true']";

import { Controller } from "@hotwired/stimulus";

export default class Multiselect extends Controller {
  static targets = ["hidden", "list", "search", "preview", "dropdown", "item", "inputContainer"]

  static values = {
    items: Array,
    selected: Array,
    disabled: { type: Boolean, default: false },
  }

  connect() {
    this.hiddenTarget.insertAdjacentHTML("afterend", this.template);
    if (this.selectedValue.length) {
      this.selectedValueChanged();
      this.inputContainerTarget.style.display = "none";
    }
    this.search = debounce(this.search.bind(this), 300);
    this.enhanceHiddenSelect();
  }

  // Allows selecting the hidden select field from html and extracting selected id values:
  // document.getElementById("selectId").values - [2, 4, 23]
  enhanceHiddenSelect() {
    Object.defineProperty(this.hiddenTarget, "values", {
      get: () => {
        if (this.selectedValue.length <= 0) return [];

        return this.selectedValue.map(item => item.value);
      },
    });
  }

  search() {
    this.searchLocal();
  }

  searchLocal() {
    this.dropdownTarget.classList.add("block");
    if (this.searchTarget.value === "") {
      let theRestOfTheItems = this.itemsValue.filter(x => !this.selectedValue.map(y => y.value).includes(x.value));
      this.listTarget.innerHTML = this.selectedItems;
      this.listTarget.insertAdjacentHTML("beforeend", this.items(theRestOfTheItems));
    }

    let searched = this.itemsValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase());
    });

    let selectedSearched = this.selectedValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase());
    });

    searched = searched.filter(x => !selectedSearched.map(y => y.value).includes(x.value));
    this.listTarget.innerHTML = this.items(selectedSearched, true);
    this.listTarget.insertAdjacentHTML("beforeend", this.items(searched));
    if (searched.length === 0) this.listTarget.insertAdjacentHTML('beforeend', this.noResultTemplate);
  }

  toggleDropdown() {
    if(this.disabledValue) return;
    if (this.dropdownTarget.classList.contains("block")) {
      this.dropdownTarget.classList.remove("block");
      if (this.selectedValue.length > 0) this.inputContainerTarget.style.display = "none";
      this.searchTarget.blur();
      this.dropdownTarget.classList.add("hidden");
    } else {
      if (this.itemsValue.length) this.dropdownTarget.classList.add("block");
      this.dropdownTarget.classList.remove("hidden");
      this.searchTarget.focus();
    }
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return;

    this.dropdownTarget.classList.remove("block");
    this.dropdownTarget.classList.add("hidden");
    if (this.selectedValue.length > 0) this.inputContainerTarget.style.display = "none";
    this.searchTarget.value = "";
    this.listTarget.innerHTML = this.allItems;
    this.selectedValue.forEach(selected => {
      this.checkItem(selected.value);
    });
  }

  itemsValueChanged() {
    if (!this.hasListTarget) return;

    if (this.itemsValue.length) {
      this.listTarget.innerHTML = this.items(this.itemsValue);
    }
  }

  selectedValueChanged() {
    if (!this.hasPreviewTarget) return;

    while (this.hiddenTarget.options.length) this.hiddenTarget.remove(0);

    if (this.selectedValue.length > 0) {
      this.previewTarget.innerHTML = this.tags;

      this.selectedValue.forEach(selected => {
        const option = document.createElement("option");
        option.text = selected.text;
        option.value = selected.value;
        option.setAttribute("selected", true);
        this.hiddenTarget.add(option);
      });

      this.selectedValue.forEach(selected => {
        this.checkItem(selected.value);
      });
    } else {
      this.searchTarget.style.paddingTop = "0";
      this.inputContainerTarget.style.display = "";
      this.previewTarget.innerHTML = this.placeholderTemplate;
    }

    this.element.dispatchEvent(new Event("multiselect-change"));
  }

  removeItem(e) {
    e.stopPropagation();

    const itemToRemove = e.currentTarget.parentNode;

    this.selectedValue = this.selectedValue.filter(x => x.value.toString() !== itemToRemove.dataset.value);
    this.uncheckItem(itemToRemove.dataset.value);
    this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: itemToRemove.dataset.value } }));
  }

  uncheckItem(value) {
    const itemToUncheck = this.listTarget.querySelector(`input[data-value="${value}"]`);

    if (itemToUncheck) itemToUncheck.checked = false;
  }

  checkItem(value) {
    const itemToCheck = this.listTarget.querySelector(`input[data-value="${value}"]`);

    if (itemToCheck) itemToCheck.checked = true;
  }

  toggleItem(input) {
    const item = {
      text: input.dataset.text,
      value: input.dataset.value,
    };
    let newSelectedArray = this.selectedValue;

    if (input.checked) {
      newSelectedArray.push(item);

      if (this.focusedItem) {
        this.focusedItem.removeAttribute("aria-selected");
      }

      input.setAttribute("aria-selected", "true");
      this.element.dispatchEvent(new CustomEvent("multiselect-added", { detail: { item: item } }));
    } else {
      newSelectedArray = newSelectedArray.filter(selected => selected.value.toString() !== item.value);
      this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: item.value } }));
    }

    this.selectedValue = newSelectedArray;
  }

  onKeyDown(e) {
    const handler = this[`on${e.key}Keydown`];
    if (handler) handler(e);
  }

  onArrowDownKeydown = (event) => {
    const item = this.sibling(true);
    if (item) this.navigate(item);
    event.preventDefault();
  }

  onArrowUpKeydown = (event) => {
    const item = this.sibling(false);
    if (item) this.navigate(item);
    event.preventDefault();
  }

  onBackspaceKeydown = () => {
    if (this.searchTarget.value !== "") return;
    if (!this.selectedValue.length) return;

    const selected = this.selectedValue;
    const value = selected.pop().value;

    this.uncheckItem(value);
    this.selectedValue = selected;
    this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: value } }));
  }

  onEnterKeydown = () => {
    if (this.focusedItem) this.focusedItem.click();
  }

  onEscapeKeydown = () => {
    if (this.searchTarget.value !== "") {
      this.searchTarget.value = "";
      return this.search();
    }

    this.toggleDropdown();
  }

  sibling(next) {
    const options = this.itemTargets;
    const selected = this.focusedItem;
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  navigate(target) {
    const previouslySelected = this.focusedItem;
    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected");
    }

    target.setAttribute("aria-selected", "true");
    target.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  get focusedItem() {
    return this.listTarget.querySelector(activeSelector);
  }

  focusSearch() {
    this.inputContainerTarget.style.display = "";
    this.searchTarget.focus();
  }

  get template() {
    return `
      <div class="box-border relative items-center gap-1 h-fit w-full text-base text-maint-font w-full rounded-lg border border-solid border-stone-300 bg-select-carret disabled:bg-none appearance-none disabled:text-muted error:bg-white bg-select-carret" data-multiselect-target="container" data-action="click->multiselect#toggleDropdown focus->multiselect#focusSearch" tabindex="0">
        <div class="d-flex items-end rounded-lg h-full min-h-8 w-full px-3 py-1 gap-1 outline-none disabled:bg-none appearance-none" data-multiselect-target="preview">
        </div>
      </div>
      <div class="relative" data-action="click@window->multiselect#closeOnClickOutside">
        <div class="bg-white border border-solid border-stone-300 w-full rounded hidden mt-1 z-50 absolute" data-multiselect-target="dropdown">
          <div class="d-flex" data-multiselect-target="inputContainer">${this.searchTemplate}</div>
          <ul class="m-0 p-0 list-none overflow-y-auto max-h-96" data-multiselect-target="list">
            ${this.allItems}
          </ul>
        </div>
      </div>
    `;
  }

  get searchTemplate() {
    return `
      <div class="d-flex my-2 w-full max-h-fit mx-6 items-center border border-solid border-stone-300 rounded">
        <i class="pl-2 fa-regular fa-magnifying-glass"></i>
        <input type="text" class="${this.disabledValue === true ? 'hidden' : ''} w-full pl-3 bg-transparent border-none outline-none text-base text-maint-font" placeholder="${this.element.dataset.placeholder}"
             data-multiselect-target="search" ${this.disabledValue === true ? 'disabled' : ''}
             data-action="multiselect#search keydown->multiselect#onKeyDown">
      </div>
    `;
  }

  get placeholderTemplate() {
    return `
      <input type="text" class="${this.disabledValue === true ? 'hidden' : ''} w-full pl-3 bg-transparent border-none outline-none text-base text-maint-font" placeholder="${this.element.dataset.placeholder}">
    `;
  }

  get noResultTemplate() {
    return `
      <li class="pl-5 block items-center text-base">
        <label class="d-flex p-1 pl-4 items-center">
          <span class="text-muted text-sm"> ${I18n.t("helpers.empty_search")} </span>
        </label>
      </li>
    `;
  }

  items(items, selected = false) {
    const checked = selected ? "checked" : "";
    let itemsTemplate = "";

    items.forEach(item => itemsTemplate += this.itemTemplate(item, checked));

    return itemsTemplate;
  }

  get tags() {
    let itemsTemplate = "";

    this.selectedValue.forEach(item => itemsTemplate += this.tagTemplate(item));

    return itemsTemplate;
  }

  get selectedItems() {
    return this.items(this.selectedValue, true);
  }

  get allItems() {
    return this.items(this.itemsValue);
  }

  itemTemplate(item, selected = "") {
    return `
      <li class="hover:bg-slate-100 block items-center text-base">
        <label class="d-flex p-1 pl-4 cursor-pointer items-center">
          <input type="checkbox" ${ selected } data-value="${item.value}" data-text="${item.text}"
          data-action="multiselect#checkBoxChange" class="pl-1 appearance-none" data-multiselect-target="item" tabindex="-1">
          <span>${item.text}</span>
        </label>
      </li>
    `;
  }

  checkBoxChange(event) {
    event.preventDefault();
    this.searchTarget.focus();
    this.toggleItem(event.currentTarget);
  }

  tagTemplate(item) {
    if (this.disabledValue) {
      return `<div class="tg-neutral tg-sm" data-value="${item.value}" title="${item.text}">
        <span class="truncate">${item.text}</span>
      </div>`;
    } else {
      return `<div class="tg-primary tg-sm" data-value="${item.value}" title="${item.text}">
        <span class="truncate">${item.text}</span>
        <span data-action="click->multiselect#removeItem">
          <i class="fa-regular fa-xmark fa-lg"></i>
        </span>
      </div>`;
    }
  }
}

function debounce(fn, delay) {
  let timeoutId = null;

  return (...args) => {
    const callback = () => fn.apply(this, args);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(callback, delay);
  };
}

export { Multiselect };
