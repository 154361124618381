<template>
  <div  class="laila-chart-component">
    <span class="text-base font-bold mb-2">{{ title }}</span>
    <div class="laila-chart-wrapper">
      <Chart class= "laila-chart" :options="chartOptions" />
      <div class="chart-label-container">
        <span v-if="total" class="chart-label text-title-3 text-main-font"> {{ total }}{{ currency }} </span>
      <span v-if="liabilities" class="text-muted"> {{ $t('lite.estates.summary_component.liabilities') }}: {{ liabilities }}{{ currency }} </span>
      </div>
    </div>

    <div class="legend-container">
      <div v-for="(chunk, i) in chartDataInChunks" class="chart-col mr-2">

        <div v-for="({name, value}, j) in chunk" class="legend-item mb-2">
          <div class="legend-color mr-2" :class="`bg-color-chart${j + (i * 4) }`"></div>
          <span class="text-small truncate"> {{ name }} </span>
          <div class="text-tiny lite-text-tiny text-muted legend-data">
            ({{ value }}%)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart } from "highcharts-vue"
import { withDefaults, toRefs } from "vue"
import { intoChunks } from "@utils"

type Props = {
  title?: string
  chartData?: string
  total?: string
  liabilities?: string|null
  currency?: string
}

const props = withDefaults(defineProps<Props>(), {
  currency: "€",
  liabilities: null
})

const { chartData, title } = toRefs(props)

const parsedChartData = chartData?.value ? JSON.parse(chartData.value) : [];

const chartDataInChunks = intoChunks(parsedChartData?.map((e, i) => ({...e, i}) ), 4)

const chartOptions = {
  credits: {
		enabled: false,
	},
  chart: {
    type: 'pie',
    height: '230px'
  },
  title: true,
  plotOptions: {
    pie: {
      size: '218px',
    },
    series: {
      enableMouseTracking: false,
    }
  },
  series: [{
    data: parsedChartData?.map(point => ({ name: point.name, y: parseInt(point.value)})),
    name: title?.value,
    innerSize: '70%',
    dataLabels: {
      enabled: false,
    }
  }]
}
</script>

<style scoped lang="scss">

.laila-chart-component {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

.laila-chart-wrapper {
  position: relative;
  width: 218px;
  height: 218px;
}

.chart-label-container {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:deep(.highcharts-background) {
  background: transparent;
  opacity: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.highcharts-point) {
  stroke: transparent;
}

:deep(.highcharts-container) {
  width: 100% !important;
}

:deep(.highcharts-root) {
  width: 100%;
}

.chart-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.chart-label {
  font-size: 20px;
  font-weight: 700;
}

.legend-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 60px;
  width: 100%;
  gap: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 0;
  min-width: 0;
}

.legend-color {
  height: 14px;
  min-width: 14px;
  border-radius: 4px;
}
</style>
