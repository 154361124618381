import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    currentYear: String,
    hideValue: Boolean
  }

  currentYearValueChanged() {
    this.initChart();
  }

  initChart() {
    pastPerformancesChartInit("pastPerformancesChart", this.currentYearValue, { hideValue: this.hideValueValue });
  }

  changeYear(event) {
    this.currentYearValue = event.target.value;
  }
}
