import { Controller } from "@hotwired/stimulus"

// For the moment we are still relying on the legacy implementation with jquery here.

export default class extends Controller {
  static values = {
    unreadCount: Number
  }
  connect() {
    this.element.innerHTML = this.buttonTemplate;

    if (this.unreadCountValue > 0) {
      $('.openGroupChat .fa-stack').attr('data-count', this.unreadCountValue);
    }
  }

  get buttonTemplate() {
    return `<button type="button" class="flex flex-center openGroupChat btn btn-big-icon">
      <span class="fa-stack fa-lg">
         <i class="fa fa-square fa-stack-2x"></i>
         <i class="fal fa-comment-alt-lines fa-stack-1x fa-inverse"></i>
       </span>
    </button>`
  }

  disconnect() {
  }
}
