import ChartAbstract from './chart_abstract';
import Highcharts from "highcharts";

export default class AssetOwnershipPie extends ChartAbstract {

  highchartsOptions() {
    const {
      suffix
    } = this.chartOptions
    return {
      chart: {
        type: 'pie',
        height: 250,
        style: { fontFamily: 'inherit' }
      },
      title: { text: '' },
      plotOptions: this.plotOptions,
      series: this.series,
      tooltip: {
          valueSuffix: suffix,
          valueDecimals: 2
        },
      credits: { enabled: false},
      yAxis: { title: { text: '' } },
      legend: this.legendFormatter
    }
  }

  get legendFormatter() {
    const { suffix } = this.chartOptions
    return {
      title: {
        text: I18n.t("js.ownership_chart_legend_title"),
        style: {
          width: 150,
          fontWeight: "light"
        }
      },
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      width: 150,
      itemWidth: 150,
      itemMarginTop: 10,
      itemMarginBottom: 0,
      symbolHeight: 15,
      symbolRadius: 3,
      useHTML: true,
      itemStyle: {
        lineHeight: "13px",
        fontWeight: 'normal',
        textOverflow: "null"
      },
      labelFormatter: function() {

        return suffix === "%"
          ? this.name
          : this.name + '<br><span class="small text-gray">' + this.info + '</span>';
      }
    }
  }

  get plotOptions() {
    return {
      pie: {
        shadow: false,
        center: ['50%', '50%']
      },
      series: {
          point: {
              events: {
                  legendItemClick: function () {
                      var id = this.id,
                          data = this.series.chart.series[0].data;
                      data.forEach(point => {
                          if (point.parentId === id) {
                            point.setVisible(!point.visible);
                          }
                      });
                  }
              }
          }
      }
    }
  }

  get series() {
    const { suffix } = this.chartOptions;
    return [
      {
          name: I18n.t("enums.asset_ownership.ownership_type.np"),
          data: this.formattedSeries.npSerie,
          size: '50%',
          showInLegend: false,
          dataLabels: {
              formatter: function () {
                  return this.y > 5 && suffix === "%" ? (Highcharts.numberFormat(this.y, 1) + suffix) : null;
              },
              style: {
                  fontWeight: "light"
              },
              distance: -20
          }
      }, {
          name: I18n.t("enums.asset_ownership.ownership_type.us"),
          data: this.formattedSeries.usSerie,
          size: '100%',
          innerSize: '60%',
          showInLegend: true,
          dataLabels: {
              formatter: function () {
                  // display only if larger than 1
                  return this.y > 1 && suffix === "%" ? (Highcharts.numberFormat(this.y, 1) + suffix) : null;
              },
              style: {
                  fontWeight: "light"
              },
              distance: -20
          }
        }
    ]
  }

  get formattedSeries() {
    const { np: npData, us: usData, info: infoData } = this.chartOptions.data[0];

    const npSerie = Object.entries(npData).map(([name, value], index) => ({
      name,
      y: parseFloat(value),
      parentId: index,
    }));

    const usSerie = Object.entries(usData).map(([name, value], index) => ({
      name,
      y: parseFloat(value),
      id: index,
      info: infoData[name],
      brightness: 0.2 - (index / Object.keys(usData).length) / 5,
    }));

    return { npSerie, usSerie };
  }

}
