import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeTable();
    // reinitalize the dataTable after tubro morph
    document.addEventListener("turbo:morph", this.reinitalize.bind(this));
  }

  initializeTable() {
    const tableElement = this.element.querySelector("table");
    this.dataTable = $(tableElement).DataTable({
      scrollY: "200px",
      scrollCollapse: true,
      paging: false,
      colResize: false,
      language: message_translations(),
      scrollCollapse: true,
      responsive: true,
      info: false,
      ordering: true,
      searching: false,
      columnDefs: [{ orderable: false, targets: 3 }],
      order: [[0, "asc"]]
    });
  }

  reinitalize() {
    this.dataTable.destroy();
    this.initializeTable();
  }
}
