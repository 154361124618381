import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shiftable"];
  static classes = ["shifting"]

  shift() {
    if (this.hasShiftableTarget && this.hasShiftingClass) {
      this.shiftableTargets.forEach((target) => {
        if (target === event.target.closest('a')) {
          this.shiftingClasses.forEach((cls) => {
            target.classList.add(cls);
          });
        } else {
          this.shiftingClasses.forEach((cls) => {
            target.classList.remove(cls);
          });
        }
      });
    }
  }
}
