import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper"]

  static values = {
    frameId: String,
    frameClass: String
  }

  insert(event) {
    if (event.params.prevent) {
      event.preventDefault();
    }
    let url = event.params.url;
    this.wrapperTarget.innerHTML = `<turbo-frame class='${this.frameClassValue}' id=${this.frameIdValue} src=${url}></turbo-frame>`;
  }
}
