export default class ChartAbstract {
  constructor(chartOptions) {
    this.chartOptions = chartOptions;
  }

  // Abstract method to be implemented by subclasses
  highchartsOptions() {
    throw new Error('getHighchartsOptions() must be implemented in subclasses');
  }
}
