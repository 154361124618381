import { Controller } from "@hotwired/stimulus"

// Provides utility methods for working with forms (e.g. filling in select), ideal as an outlet for other controllers
export default class extends Controller {
  // We assume the field is controlled so prevent default (e.g. opening a dropdown)
  static targets = ["hiddenSelect", "selectedList", "selectedTemplate"]

  selectedValues() {
    return [...this.hiddenSelectTarget.options].map(option => option.value);
  }

  // Fill in list according to hidden template and provided values
  fillMultiSelect({ options }) {
    // Fill in options in visible non functional selected-templates (e.g. labels)
    this.selectedListTarget.innerHTML = "";
    options.forEach(element => {
      const clone = this.selectedTemplateTarget.cloneNode(true);
      clone.innerText = element.text;
      clone.style.display = null;
      this.selectedListTarget.appendChild(clone);
    });
    // Fill options in hidden select target
    this.hiddenSelectTarget.innerHTML = "";
    options.forEach(element => {
      const clone = document.createElement("option");
      clone.innerText = element.text;
      clone.value = element.value;
      clone.setAttribute("selected", true); // For some reason this needs to be setAttribute
      this.hiddenSelectTarget.appendChild(clone);
    });
  }
}
