import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["listener"]

  connect() {
    this.element.addEventListener("change", this.processChange.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("change", this.processChange.bind(this))
  }

  processChange(event) {
    this.listenerTargets.forEach((listenerTarget) => {
      if (event.detail) {
        if (listenerTarget.dataset.listenerField) {
          let compositeDetail = JSON.parse(event.detail)
          listenerTarget.value = compositeDetail[listenerTarget.dataset.listenerField];
        } else {
          listenerTarget.value = event.detail;
        }
      } else {
        listenerTarget.value = null;
      }
      event.stopPropagation();
      let newEvent = new CustomEvent("change", { bubbles: false, cancelable: true, detail: event.detail});
      listenerTarget.dispatchEvent(newEvent);
    })
  }
}
