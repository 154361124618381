<template>
  <Popper arrow hover placement="bottom">
    <div id="notifications-button" :class="props.config.selector">
      <i class="fa fa-regular fa-gift notifications-button__icon" />
    </div>
    <template #content>
      <span v-html="$t('layout.navbar.notifications_tooltip')" />
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "vue3-popper";
import { loadBeamer, BeamerConfig } from "@utilities/beamer";
import { onMounted } from "vue";

type Props = {
  config: BeamerConfig;
};

const props = defineProps<Props>();

onMounted(() => {
  props.config && loadBeamer({ ...props.config });
});
</script>

<style lang="scss" scoped>
:deep(.beamer_icon.active) {
  right: -10px;
  top: -10px;
}
#notifications-button {
  align-items: center;
  width: 32px;
  height: 32px;
  justify-content: space-around;
  display: flex;
  margin-right: 0.5rem;
  &:hover {
    .notifications-button__icon {
      color: var(--primary);
    }
  }
}
.notifications-button__icon {
  font-size: 16px;
}
</style>
