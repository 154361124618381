import ChartAbstract from './chart_abstract';
import Highcharts from "highcharts";

var insuranceChartColors = [window.primaryColor, window.secondaryColor, { patternIndex: 0 }]

export default class InsuranceValue extends ChartAbstract {
  highchartsOptions() {
    return {
      chart: {
        type: 'areaspline',
        height: 250,
        borderRadius: "10px",
        style: {
            fontFamily: 'inherit'
        }
      },
      color: {

      },
      title: {
        text: '',
        align: 'left',
        style: {
            "color": window.textColor,
            "fontSize": "16px",
            "textTransform": "uppercase"
        }
      },
      series: this.series,
      legend: {
        itemMarginTop: 5,
        itemMarginBottom: 10,
        symbolHeight: 15,
        symbolRadius: 3,
        backgroundColor: 'white',
        useHTML: true,
        itemStyle: {
            lineHeight:"13px",
            fontWeight: 'normal'
        },
      },
      lang: { noData: ''},
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            year: '%Y'
        },
        plotBands: [{
            color: '#b3b5b7',
            from: this.startDate, //first_date.to_time.utc.to_i*1000,
            to: Date.now() //Time.now.utc.to_i*1000
        }]
      },
      yAxis: { title: { text: ''}},
      credits: { enabled: false },
      tooltip: {
        xDateFormat: '%d %b %Y',
        pointFormat: '{series.name}: <b>{point.y}</b><br>',
        valueSuffix: ' EUR',
        valueDecimals: 0,
        shared: true
      },
      plotOptions: {
        areaspline: {
            step: 'right',
            // stacking: 'normal',
            marker: {
                lineWidth: 2,
                lineColor: window.primaryColor,
                fillColor: '#FFFFFF',
                enabled: false,
                radius: 4,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            }
        }
      }
    }
  }

  get series() {
    const { data } = this.chartOptions;
    return data.map((serie, index) => {
      serie.color = insuranceChartColors[index];
      return serie;
    });
  }

  get startDate() {
    const { data } = this.chartOptions;

    if (data[0]?.data[0]?.[0]) {
      return new Date(data[0].data[0][0]).getTime();
    } else {
      return Date.now();
    }
  }
}
