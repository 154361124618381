import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  /* initialize with controller: "global-event", global_event_name_param: 'some-event-name' */

  toggleSlideout() {
    this.dispatch("toggle-slideout");
  }

  toggleModal() {
    this.dispatch("toggle-modal");
  }
}
