import ChartAbstract from './chart_abstract';
import { numberFormat, dateFormat } from "highcharts";

export default class PastValueChart extends ChartAbstract {
  highchartsOptions() {
    const { currency } = this.chartOptions;
    return {
      chart: {
        height: 200
      },
      rangeSelector: {
        enabled: false
      },
      navigator : {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      data: {
        dateFormat: "dd/mm/YYYY"
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function() {
          const header = dateFormat('%B %Y', this.x) + '<br>';
          return header + "<b>" + numberFormat(this.y, 0) + "</b>";
        }
      },
      series: this.series,
      xAxis: {
        type :'datetime',
        ordinal: false,
        labels: {
          formatter() {
            return dateFormat('%b %y', this.value);
          }
        }
      },
      yAxis: [{
        labels: {
          formatter: function() {
            if ( this.value >= 1000000 ) {
              return numberFormat(this.value/1000000, 1) + "M " + currency;
            } else if ( this.value >= 1000 ) {
              return numberFormat(this.value/1000, 1) + "K " + currency;
            } else {
              return numberFormat(this.value,0) + " " + currency;
            }
          }
        },
        title: {
            text: ''
        },
        opposite: false
      }]
    }
  }

  get series() {
    const { data } = this.chartOptions;
    return [{
      name: I18n.t("product_sheet.past_situations.chart.series.total"),
      data: data["serie"],
      type: 'area',
      stacking: 'normal'
    }];
  }
}
