import ChartAbstract from './chart_abstract';
import Highcharts from "highcharts";

export default class AggregateLoanBalances extends ChartAbstract {

  highchartsOptions() {
    return {
      chart: {
        type: 'area',
        height: 250,
        zoomType: 'x',
        style: {
            fontFamily: 'inherit'
        }
      },
      title: {
        text: '',
        align: 'left',
        style: {
            "color": window.textColor,
            "fontSize": "16px",
            "textTransform": "uppercase"
        }
      },
      legend: { enabled: false },
      lang: { noData: I18n.t("chart.legend.no_data_to_display") },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            month: '%b %y',
            year: '%b %y'
        },
        plotBands: [{
            color: '#b3b5b7',
            from: this.xAxisPlotBand,
            to: Date.now()
        }]
      },
      yAxis: { title: { text: '' } },
      credits: { enabled: false },
      tooltip: {
        xDateFormat: '%d %b %Y',
        pointFormat: I18n.t("chart.legend.remaining_balance") +': <b>{point.y}</b>',
        valueSuffix: ' ' + 'EUR',
        valueDecimals: 0,
        shared: true
      },
      plotOptions: {
        area: {
            step: 'left',
            marker: {
                lineWidth: 2,
                lineColor: window.primaryColor,
                fillColor: '#FFFFFF',
                enabled: true,
                radius: 4,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            }
        }
      },
      series: this.series
    }
  }

  get series() {
    const { data } = this.chartOptions;
    const series = [{
        name: '',
        data: data,
        color: window.secondaryColor
    }];
    return series
  }

  get xAxisPlotBand() {
    const { data } = this.chartOptions;
    if (data[0]) {
      return data[0][0];
    } else {
       return Date.now()
    }
  }
}
