import { Controller } from "stimulus";
import TableFactory from "../utilities/product_sheet_tables/table_factory";


export default class extends Controller {
  static values = { tableName: String, data: String }

  initialize() {
    this.dataTableInstance = null;
  }

  connect() {
    try {
      const data = JSON.parse(this.dataValue)
      const table = TableFactory.createTable(this.tableNameValue, data);
      const dataTableOptions = table.dataTableOptions();

      if (!this.element.hasChildNodes() && !this.dataTableInstance) {
        const { estate_id, asset_id} = data
        window.estateObjectId = asset_id;
        window.estateObjectEstateId = estate_id;
        window.portfolioPositionsTable = $(this.element).DataTable(dataTableOptions);
      }
    } catch (error) {
      console.error('Error initializing DataTable:', error);
    }
  }

  disconnect() {
    if (this.dataTableInstance) {
      this.dataTableInstance.destroy();
      this.dataTableInstance = null;
    }
  }
}
