// Provides utility methods for working with forms (e.g. filling in select), ideal as an outlet for other controllers

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // We assume the field is controlled so prevent default (e.g. opening a dropdown)
  connect() {
    this.element.addEventListener("mousedown", (event) => {
      event.preventDefault();
      this.element.blur();
      window.focus();
    })
  }

  fillSelect({ text, value }) {
    this.element.options[0].selected = true
    this.element.options[0].value = value
    this.element.options[0].innerText = text
  }
}
