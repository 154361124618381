import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hidden", "select"];

  connect() {
    const selectedOptions = Array.from(this.selectTarget.querySelectorAll('option[selected]'));

    if(selectedOptions.length > 1) {
      const community = (this.hiddenTarget.value === 'true');

      const communityOption = selectedOptions.find(option => option.dataset.community)
      const familyHeadOption = selectedOptions.find(option => !option.dataset.community)

      if(community) {
        communityOption.selected = true
      } else {
        familyHeadOption.selected = true
      }
    }
    this.selectTarget.addEventListener("change", this.select.bind(this))
  }

  select(e) {
    this.hiddenTarget.value = !!e.target.querySelector('option:checked').dataset.community;
  }
}
