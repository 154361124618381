import TableAbstract from './table_abstract';
import moment from "moment";
import { messageTranslations } from '../library_translation';

export default class RegularPortfolioTable extends TableAbstract {

  dataTableOptions() {
    const { source } = this.tableOptions;
    return {
      paging: false,
      lengthChange: false,
      info: false,
      searching: false,
      language: messageTranslations(),
      autoWidth: true,
      colResize: false,
      deferRender: true,
      processing: true,
      ajax: {
        url: source,
        dataSrc: ""
      },
      columns: this.positionColumns,
      columnDefs: [
        {
          render: function(data, type, row, meta) {
            var colClasses = meta.settings.aoColumns[meta.col].className;
            if (colClasses && colClasses.includes("percentage") && $.isNumeric(data) && (type === 'display' || type === 'filter')) {
              return window.percentFormatWithSign.to(parseFloat(data));
            } else if (colClasses && colClasses.includes("value") && $.isNumeric(data) && (type === 'display' || type === 'filter')) {
              var moneyString = window.moneyFormat.to(data);
              return window.replaceMoneyCurrency(moneyString, '€', row.currency[1]);
            } else if (typeof data === 'boolean') {
              return
            } else {
              return $.fn.dataTable.render.text().display(data, type, row, meta);
            }
          }, targets: '_all',
        }
      ],
      responsive: {
        details: {
          renderer: function(api, rowIdx, columns) {
            var data;
            data = $.map(columns, function(col, i) {
              if (col.hidden) {
                if (col.title && col.data) {
                  return $("<tr></tr>").append($("<td></td>", {
                    text: col.title + ": ",
                  })).append($("<td></td>", {
                    text: col.data,
                  })).prop("outerHTML");
                } else if (col.data) {
                  return $("<tr></tr>").append($("<td></td>", {
                    text: col.data,
                  })).prop("outerHTML");
                }
              } else {
                return "";
              }
            }).join("");
            if (data) {
              return $("<table/>").append(data).prop("outerHTML");
            } else {
              return false;
            }
          },
        },
      },
      drawCallback: function (settings) {
        $('.deletePosition, .editPosition').css('cursor', 'pointer');
      },
      initComplete: function(settings) {
        var portfolioPositionsTable = $(this).DataTable().table();

        portfolioPositionsTable.on('click', 'tbody td .deletePosition', function() {
          var row = portfolioPositionsTable.row($(this).closest('tr'));
          window.deletePositionRow(row); // Use self to refer to the class method
        });

        portfolioPositionsTable.on('click', 'tbody td .editPosition', function() {
           var row = portfolioPositionsTable.row($(this).closest('tr'));
          console.log(row)
          window.editPositionRow(row);
        });
      },
      order: [[ 2, "desc" ]],
    }
  }

  get positionColumns() {
    const { readonly } = this.tableOptions;
    return [
      {
        data: 'asset_type',
        title: I18n.t("activerecord.attributes.portfolio_position.asset_type")
      },
      {
        data: 'name',
        title: I18n.t("activerecord.attributes.portfolio_position.name")
      },
      {
        data: 'current_value',
        title: I18n.t("activerecord.attributes.portfolio_position.current_value"),
        className: 'align-left',
        render: (data, type, row) =>{
          if(type === "sort" || type === "type"){
            return row.euro_current_value;
          }
          return window.replaceMoneyCurrency(moneyFormat.to(data), '€', row.currency[1]);
        }
      },
      {
        data: 'weight',
        title: I18n.t("activerecord.attributes.portfolio_position.weight"),
        className: 'percentage align-left',
      },
      {
        data: 'performance_since_inception',
        title: I18n.t("activerecord.attributes.portfolio_position.performance_since_inception"),
        className: 'percentage align-left',
      },
      {
        data: 'situation_date',
        title: I18n.t("activerecord.attributes.portfolio_position.situation_date"),
        className: 'align-left',
        type: "date",
        render: function(data, type, row){
          if(type === "sort" || type === "type"){
            return data;
          }
          return moment(data).format('DD/MM/YYYY');
        }
      },
      {
        data: 'isin',
        title: I18n.t("activerecord.attributes.portfolio_position.isin"),
        className: 'none align-left'
      },
      {
        data: 'quantity',
        title: I18n.t("activerecord.attributes.portfolio_position.quantity"),
        className: 'none',
        render: function ( data, type, row ) {
          return data.toLocaleString();
        }
      },
      {
        data: 'avg_purchase_price',
        title: I18n.t("activerecord.attributes.portfolio_position.avg_purchase_price"),
        className: 'none value align-left',
      },
      {
        data: 'price',
        title: I18n.t("activerecord.attributes.portfolio_position.price"),
        className: 'none value align-left',
      },
      {
        data: 'value_since_inception',
        title: I18n.t("activerecord.attributes.portfolio_position.value_since_inception"),
        className: 'none value align-left',
      },
      {
        data: '',
        defaultContent: "",
        orderable: false,
        className: 'align-left all',
        render: () => {
          if (readonly) {
            return '';
          } else {
            return '<span class="d-flex gap-1"><i class="fas fa-pencil editPosition"></i><span></span><i class="ml5 fas fa-circle-xmark deletePosition delete"></i></span>';
          }
        }
      }
    ]
  }

  deletePositionRow(row){
    const { estate_id, asset_id } = this.tableOptions;
    var data = row.data();
    var deleteUrl = `/estates/${estate_id}/portfolio_positions/${data.id}`
    $.ajax({
      url: deleteUrl,
      method: 'delete',
      data: {
        'estate_object_id': asset_id,
      },
      success: () => {
        $(this.element).row(row).remove().draw();
      }
    });
  }


  editPositionRow(row){
    const { estate_id, asset_id } = this.tableOptions;
    var data = row.data();
    var editUrl = `/estates/${estate_id}/portfolio_positions/${data.id}/edit`
    $.ajax({
      url: editUrl,
      method: 'get',
      data: {
        'estate_object_id': asset_id,
      },
      success: () => {
        $(this.element).row(row).data();
      }
    });
  }
}
