import { Controller } from "@hotwired/stimulus"

// Can forward data to the form select controller (outlet)
export default class extends Controller {
  static outlets = ["form-multi-select"]
  static targets = ["checkbox"]

  initialize() {
    this.syncChecboxes() // sync initial state
    this.syncOptions()
    this.checkboxTargets.forEach((element) => {
      element.addEventListener("change", (event) => {
        this.syncOptions()
      })
    })
  }

  syncChecboxes() {
    const ids = this.formMultiSelectOutlet.selectedValues();

    this.checkboxTargets.forEach((element) => {
      const value = this.toOption(element).value
      element.getElementsByTagName('input')[0].checked = ids.includes(value)
    })
  }

  syncOptions() {
    this.options = this.checkboxTargets.map(element => this.toOption(element)).filter(option => option.checked)
  }

  toOption(node){
    const input = node.getElementsByTagName('input')[0]

    return { text: input.dataset.text, value: input.dataset.value, checked: input.checked }

  }

  submit() {
    this.formMultiSelectOutlet.fillMultiSelect({ options: this.options })
  }
}
