import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    url: String,
    method: String,
  }

  connect() {
    this.downloadFile()
  }

  downloadFile() {
    const url = this.urlValue
    if(url) {
      const link = document.createElement("a")
      link.href = url
      link.dataset.method = this.methodValue
      link.download = "" // Optionally, you can specify a filename here
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      return
    }
  }
}
