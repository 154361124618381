<template>
  <div  class="laila-chart-component">
    <span class="text-base font-bold">{{ title }}</span>
    <div class="laila-chart-wrapper">
      <Chart class= "laila-chart" :options="chartOptions" />
    </div>

    <div class="legend-container">
      <div v-for="(chunk, i) in chartDataInChunks" class="chart-col mr-2">

        <div v-for="(item, j) in chunk" class="legend-item mb-2">

        <div class="legend-color mr-2" :class="`bg-color-chart${j + (i * 4) }`"></div>

        <div class="legend-text">
          <span class="text-small legend-name truncate" > {{ item.name }} </span>
          <div class="text-tiny lite-text-tiny text-muted legend-data">
            <span v-if="item.values"> ({{ item.values }}) </span>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart } from "highcharts-vue"
import { withDefaults } from "vue"
import { intoChunks } from "@utils"

type Props = {
  title?: string
  datasets?: string
  info?: string
  color?: string|undefined
  settings: object
  unit: string|undefined
}

const props =  withDefaults(defineProps<Props>(), {
  unit: ""
})

const { datasets, info } = props

const parsedInfo = info && JSON.parse(info)

const formatSeriesData = ({ labels, serie_name }) => (value, index) => ({
  name: labels?.[index],
  serie_name,
  values: parsedInfo?.[index],
  className: `chart-color-${index}`,
  y: value,
})

const createSerie = (dataset, size) => {
  return {
    name: dataset.serie_name,
    data: dataset.data.map(formatSeriesData(dataset)),
    dataLabels: { enabled: false },
    ...size
  }
}

const parsedDataSets = JSON.parse(datasets)
const formattedDataSets = parsedDataSets.map( dataset => dataset.data.map(formatSeriesData(dataset)) )

const legends = [...new Set(...parsedDataSets.map(dataset => dataset.labels) )].map(e => {
  const [set1, set2] = formattedDataSets.map(set => set.find(el => el.name === e))
  const values = `${set1.values}`

  return {
    name: set1.name,
    values,
  }
})

const chartDataInChunks = intoChunks(legends, 4)

const series = parsedDataSets.map(
  (dataset, i) => createSerie(dataset, i && { size: "100%", innerSize: "60%"} || { size: "50%" } )
)
const chartOptions = {
  credits: { enabled: false },
  chart: {
    type: 'pie',
    height: '230px'
  },
  title: false,
  series,
  plotOptions: {
    pie: {
      size: '218px'
    },
    series: {
      enableMouseTracking: false,
    }
  },
}

</script>

<style scoped lang="scss">
@use "../../styles/foundations/colors/color-variables.scss" as colors;

.laila-chart-component {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

.laila-chart-wrapper {
  position: relative;
  width: 218px;
  height: 218px;
}

:deep(.highcharts-background) {
  background: transparent;
  opacity: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.highcharts-point) {
  stroke: transparent;
}

:deep(.highcharts-container) {
  width: 100% !important;
}

:deep(.highcharts-root) {
  width: 100%;
}

.chart-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.chart-label {
  font-size: 20px;
  font-weight: 700;
}

.legend-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 0;
  flex-wrap: nowrap;
  min-width: 0;
}

.legend-title {
  display: flex;
}

.legend-color {
  height: 14px;
  min-width: 14px;
  border-radius: 4px;
}

.legend-data {
  display: block;
}

//loops are not working for some reason
:deep(.chart-color-0) {
  fill: colors.$chart0
}
:deep(.chart-color-1) {
  fill: colors.$chart1
}
:deep(.chart-color-2) {
  fill: colors.$chart2
}
:deep(.chart-color-3) {
  fill: colors.$chart3
}
:deep(.chart-color-4) {
  fill: colors.$chart4
}
:deep(.chart-color-5) {
  fill: colors.$chart5
}
:deep(.chart-color6) {
  fill: colors.$chart6
}
:deep(.chart-color-7) {
  fill: colors.$chart7
}
:deep(.chart-color-8) {
  fill: colors.$chart8
}
:deep(.chart-color-9) {
  fill: colors.$chart9
}
:deep(.chart-color-10) {
  fill: colors.$chart10
}
:deep(.chart-color-11) {
  fill: colors.$chart11
}
:deep(.chart-color-12) {
  fill: colors.$chart12
}
:deep(.chart-color-13) {
  fill: colors.$chart13
}
:deep(.chart-color-14) {
  fill: colors.$chart14
}

</style>
