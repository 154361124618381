import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    opened: Boolean,
  }

  static targets = ["item"]

  initialize() {
    this.active_index = Number(this.element.dataset.active)
    this.highlightActiveItem()
  }

  highlightActiveItem() {
    this.itemTargets.forEach((element, index) => {
      if(index === this.active_index) {
        element.classList.add("selector__menu-item--selected")
      } else {
        element.classList.remove("selector__menu-item--selected")
      }
    })
  }

  connect() {
    this.openedValue ? this.open() : this.close()
  }

  toggle(event) {
    event.preventDefault()
    this.openedValue ? this.close() : this.open()
  }

  open() {
    this.element.classList.add("selector--opened");
    document.body.classList.add("selector--opened");
    this.openedValue = true

    if (this.focusableElements[0]) {
      this.focusableElements[0].focus()
    }
  }

  close() {
    this.element.classList.remove("selector--opened");
    document.body.classList.remove("selector--opened");
    this.openedValue = false
  }


  outsideDismiss(event) {
    // Ignore event if clicked within element
    if(this.element === event.target || this.element.contains(event.target)) {
      return;
    }

    // Execute the actual action we're interested in
    this.close();
  }

  select_href(event) {
    this.active_index = Number(event.currentTarget.dataset.index)
    this.highlightActiveItem()
  }

  select(event) {
    event.preventDefault();
    let value = event.currentTarget.dataset.value;
    let emittedEvent = new CustomEvent("change",
      {
        bubbles: true,
        detail: value
      }
    );
    this.element.dispatchEvent(emittedEvent);
    this.close();
  }

  get focusableElements() {
    // Convert NodeList to Array
    // Only handle inputs for now
    let elements = [];

    let inputSelectors = [
      "input[type='text']:not([disabled])",
      "input[type='radio']:not([disabled])",
      "input[type='checkbox']:not([disabled])",
      "textarea:not([disabled])",
      "select:not([disabled])"
    ]

    this.element.querySelectorAll(inputSelectors.join(",")).forEach( (element) => {
      if( element.dataset.skipfocus !== "true" ) {
        elements.push(element)
      }
    });

    return elements
  }
}
