import { Controller } from "@hotwired/stimulus";

import debounce from "lodash/debounce"

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 0 },
    triggers: { type: Array, default: ["input", "change"] },
    turboSubmit: { type: Boolean, default: true },
  }

  initialize() {
    if(this.turboSubmitValue) {
      this.delayedSubmit = debounce(this.submit.bind(this), this.delayValue)
    } else {
      this.delayedSubmit = debounce(this.nonTurboSubmit.bind(this), this.delayValue)
    }
  }

  connect() {
    this.attachListeners(this.element);
    this.nonDescendantInputs.forEach( (input) => {
      this.attachListeners(input)
    })
  }

  disconnect() {
    this.detachListeners(this.element);
    this.nonDescendantInputs.forEach( (input) => {
      this.detachListeners(input)
    })
  }

  attachListeners(node) {
    this.triggersValue.forEach((event) => {
      node.addEventListener(event, this.delayedSubmit);
    });
  }

  detachListeners(node) {
    this.triggersValue.forEach((event) => {
      node.removeEventListener(event, this.delayedSubmit);
    });
  }

  submit() {
    let newEvent = new CustomEvent("submit", { bubbles: true, cancelable: true, detail: null });
    this.element.dispatchEvent(newEvent);
  }

  nonTurboSubmit() {
    this.element.submit();
  }

  get nonDescendantInputs() {
    return document.querySelectorAll(`input[form="${this.element.id}"]`);
  }
}
