<template>
  <div className="laila-pincode-input" ref="pincode">
    <input
      v-for="(_, i) in pincodeLength"
      className="codebox rounded-brand"
      inputMode="numeric"
      autocomplete="one-time-code"
      :autofocus="i == 0 ? true : false"
      @input="onInput(i, $event)"
      @keypress="acceptDigit(i, $event)"
      @keyup.left="goLeft($event.target)"
      @keyup.right="goRight($event.target)"
      @keyup.backspace="backSpace(i, $event)"
      :key="i"
      :value="code[i]"
    />
    <input
      :value="code.join('')"
      type="text"
      id="code"
      name="code"
      style="visibility: hidden; height: 0; width: 0; border: 0"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch, nextTick, computed } from "vue";

type Props = {
  autoSubmit?: string | undefined;
};

const props = withDefaults(defineProps<Props>(), {
  autoSubmit: "true",
});

const pincodeLength = 6;
const code = reactive(Array(pincodeLength));
const pincode = ref(null);

const shouldSubmit = computed(() => code.join("").length === pincodeLength);

const findForm = (element) => {
  let parent = element.parentElement;
  while (parent.tagName !== "FORM") parent = parent.parentElement;
  return parent;
};

watch(code, (val) => {
  if (shouldSubmit.value && props.autoSubmit === "true") nextTick(autoSubmit);
});

const disableForm = (form) => Array.from(form.elements).forEach((el) => (el.disabled = true));
const autoSubmit = async () => {
  findForm(pincode.value)?.submit();
  disableForm(findForm(pincode.value));
};

// Required for paste "From Messages" on iOS as well
const onInput = (startIndex, e) => {
  e.preventDefault();
  const data = e.target.value;
  const digitsArray = data.match(/\d+/g) || [];
  const codeArray = [...digitsArray.join("").split("").slice(0, pincodeLength)];
  let currentElement = e.target;
  for (var i = startIndex; i < Math.min(pincodeLength, codeArray.length + startIndex); i++) {
    code[i] = codeArray[i - startIndex];
    currentElement = goRight(currentElement);
  }
};

const goLeft = (el) => {
  const previous = el.previousElementSibling;
  if (previous) {
    previous.focus();
    return previous;
  }
};

const goRight = (el) => {
  const next = el.nextElementSibling;
  if (next) {
    next.focus();
    return next;
  }
};

const backSpace = (i, el) => {
  code[i] = "";
  goLeft(el.target);
};

const isDigit = (value) => /^\d$/.test(value);

const acceptDigit = (i, e) => {
  e.preventDefault();
  if (isDigit(e.key)) {
    code[i] = e.key;
    goRight(e.target);
  }
};
</script>
