// A controller to be used in creating dynamic forms

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldset"];

  hideWhenPresent({ target, params }) {
    console.log(target.value)
    this.fieldsetTargets.forEach(fieldset => {
      if(fieldset.dataset.dynamicFormSet == params.set) {
        if (target.value) {
          fieldset.style.display = "none"
        } else {
          fieldset.style.display = "block"
        }
      }
    })
  }
}
