import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["listener"]

  connect() {
    this.element.addEventListener("focusout", this.balancePercentage.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("focusout", this.balancePercentage.bind(this));
  }

  balancePercentage(e) {
    const { input, output } = this.defineTargets(e.target.dataset.id);

    const parsedInputValue = parseFloat(input.value.replace(",", "."));
    const parsedOutputValue = output ? parseFloat(output.value.replace(",", ".")) : "";
    const inputValue = isNaN(parsedInputValue) ? parseFloat("0.0") : parsedInputValue;
    const outputValue = isNaN(parsedOutputValue) ? parseFloat("0.0") : parsedOutputValue;

    const sum = inputValue + outputValue;
    if (sum != 0 && output) {
      const diff = sum - 100;
      const endValue = outputValue - diff;
      output.value = endValue.toFixed(2);
    }
  }
  defineTargets(inputTargetId) {
    /*
      input is the target emitting the input
      output is the auto updated field - only do when there are 2 fields
    */
    const targets = this.listenerTargets;
    const inputTarget = Array.from(targets).find(target => target.dataset.id == inputTargetId);
    var outputTarget;
    if (targets.length === 2) {
      outputTarget = Array.from(targets).find(target => target.dataset.id != inputTargetId);
    }
    return { input: inputTarget, output: outputTarget };
  }
}
