import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    estateId: String,
    estateAssetId: String
  }

  reloadTable(event) {
    reloadInventoryTable(this.estateIdValue, this.estateAssetIdValue);
  }
}
