import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    opened: Boolean,
  }
  static targets = ["toggle", "greedyMenu", "toggleMenu"]

  initialize() {
    this.breakpoints = [];
  }

  connect() {
    this.element.classList.add("navigation--rendering");
    this.calculateBreakpoints();
    this.openedValue ? this.open() : this.close();
    this.layout();
    this.element.classList.remove("navigation--rendering");
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openedValue ? this.close() : this.open();
  }

  /*
    For each navigation item, calculate how much space is needed
    to accomodate it
  */
  calculateBreakpoints() {
    var childrenWidth = 0;

    for (var i = 0; i < this.greedyMenuTarget.children.length; i++) {
      childrenWidth += this.greedyMenuTarget.children[i].offsetWidth;
      this.breakpoints[i] = childrenWidth;
    }
  }

  open() {
    this.element.classList.add("navigation--opened");
    document.body.classList.add("navigation--opened");
    this.openedValue = true;
  }

  close() {
    this.element.classList.remove("navigation--opened");
    document.body.classList.remove("navigation--opened");
    this.openedValue = false;
  }

  setToggleActive() {
    this.toggleTarget.classList.remove("navigation__toggle--active")

    const isActive = [...this.toggleMenuTarget.children].some((item) => {
      return !item.classList.contains("navigation__item--hidden") && (item.dataset.active == "true")
    })

    if (isActive) {
      this.toggleTarget.classList.add("navigation__toggle--active")
    }
  }

  layout() {
    this.element.classList.add("navigation--rendering");
    // Clean state as if everything was in the toggle menu
    for (var i = 0; i < this.greedyMenuTarget.children.length; i++) {
      this.greedyMenuTarget.children[i].classList.add("navigation__item--hidden");
    }

    for (var i = 0; i < this.toggleMenuTarget.children.length; i++) {
      this.toggleMenuTarget.children[i].classList.remove("navigation__item--hidden");
    }
    this.toggleTarget.classList.remove("navigation__toggle--hidden");
    var availableSpace = this.element.offsetWidth - this.toggleTarget.offsetWidth;
    var totalItems = this.greedyMenuTarget.children.length;
    var itemsVisible = 0;

    /*
      Check if there is not enough space to make links visible in the greedy menu
    */
    while ((itemsVisible < totalItems) && (availableSpace > this.breakpoints[itemsVisible])) {
      itemsVisible++;
      this.greedyMenuTarget.children[itemsVisible - 1].classList.remove("navigation__item--hidden");
      this.toggleMenuTarget.children[itemsVisible - 1].classList.add("navigation__item--hidden");
    }

    if (itemsVisible === totalItems) {
      this.toggleTarget.classList.add("navigation__toggle--hidden");
    }

    this.setToggleActive();

    this.element.classList.remove("navigation--rendering");
  };
};

