import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "item"]
  static values = {
    activeItem: String
  }

  select(event) {
    this.activeItemValue = event.params.item;
  }

  activeItemValueChanged() {
    this.itemTargets.forEach((item) => {
      item.classList.remove(this.activeClass);

      if (item.dataset.segmentedControlItemParam == this.activeItemValue) {
        item.classList.add(this.activeClass)
      };
    });

    this.dispatch("change", { prefix: false, detail: this.activeItemValue });
  }

  get activeClass() {
    return "segmented-control__item--active";
  }
}
