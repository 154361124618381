import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["estate_asset", "insurance"]

  connect() {
  }

  toggle(event) {
    let hiddenClass = "hidden"
    
    if(event.target.value == 'estate_asset') {
      this.estate_assetTargets.forEach((element) => {
        element.classList.remove(hiddenClass);
      });
      this.insuranceTargets.forEach((element) => {
        element.classList.add(hiddenClass);
      });
    }

    if(event.target.value == 'insurance') {
      this.estate_assetTargets.forEach((element) => {
        element.classList.add(hiddenClass);
      });
      this.insuranceTargets.forEach((element) => {
        element.classList.remove(hiddenClass);
      });
    }
  }
}